.App {
    /*max-width: 1200px;*/
    margin: auto;
    font-family: Tahoma, Geneva, sans-serif;
}

.topwrap {
    top: 0;
    position: sticky;
    z-index: 10;
    background-color: white;
}

.top {
    border-bottom: 1px solid #777;
    height: 138px;
    max-width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-items: center;
}

.App-logo {
    margin-left: 15px;
    width: 186px;
}
.centeringPlaceholder{
    margin-right: 15px;
    text-align: right;
    width: 186px;
}

.user.button {
    margin-bottom: 0.75rem;
}

.dropdown-container {
    position: relative;
}

.dropdown {
    position: absolute;
    top: 45px;
    right: 0px;
    overflow: hidden;
    z-index: 10;
}

.top .dropdown .button {
    border: none;
    border-bottom: 1px solid #efefef;
    margin-top: 0px;
    padding: 15px;
    width: 160px;
    border-radius: 0;
    background-color: white;
}

.dropdown.hidden > div {
    opacity: 0;
    transform : translateY(-300px);
    height: 0;
    transition: opacity 300ms, transform 300ms, height 300ms;
}

.dropdown.active {
    
}

.dropdown.active > div {
    opacity: 1;
    transform : translateY(0px);
    height: 100%;
    transition: opacity 300ms, transform 300ms, height 300ms;
}


.main .exit {
    display: none;
}

.brand {
    margin-left: 0.8rem;
    font-size: 4rem;
}

.top div {
    display: inline-block;
}
.top img {
    height: 88px;
}

.button {
    border-radius: 5px;
    padding: 0.7rem;
    font-size: 2rem;
    display: inline-block;
    /*margin: 1em;*/
    border: 1px solid #0033a0;
    background-color: #fff;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.button:hover {
    background-color: #0033a0;
    color: white;
}

.button.inactive {
    opacity: 0.5;
    background-color: #cfcfcf;
    cursor : not-allowed;
}
.button.inactive:hover {
    background-color : grey;
    color: white;
}

.field input {
    font-size: 2rem;
    padding: 5px;
}

.field select {
    font-size: 2rem;
    padding: 5px;
}

.formgroup {
    border: 1px solid #888;
    padding: 1em;
    /*width: 400px;*/
    display: flex;
    flex-wrap: wrap;
}

.formgroup h3 {
    width: 100%;
}

.formitem:nth-child(0) {
    margin-top: 0;
}
.formitem {
    margin-top: 12px;
    margin-left: 2em;
    margin-right: 2em;
}


.form > .button {
    align-self: start;
    justify-self: start;
}

.field {
    padding: 6px;
}

.highlight .field {
    border: 3px solid yellow;
}

.top .brand {
    margin: auto;
}

.main .buttons {
    margin-top: 0.5rem;
    display: flex;
}

.buttons .button {
    margin-left: 1rem;
    margin-right: 1rem;
}

.buttons .button:nth-child(1) {
    margin-left: auto;
}

.buttons .button:last-child {
    margin-right: auto;
}

.buttons .button.right {
    margin-right: 0;
    margin-left: auto;
}


.main > .button {
    margin-top: 0.5rem;
}

.top .button {
    font-size: 1rem;
    border: none;
    background-color: transparent;
    height: auto;
    padding: 0;
    justify-self: flex-end;
    margin-bottom: 0;
}

.top .buttons {
    justify-self: flex-end;
    display: flex;
}

.top .button:hover {
    text-decoration: underline;
    background-color: white;
    color: black;
}

.main {
    min-width: 360px;
    margin: auto;
}

.main > div {
    margin: auto;
    max-width: 800px;
}

.main > div.form {
    max-width: 1200px;
}

.blinder {
    position: fixed;
    left: 0;
    top : 0;
    height: 100vh;
    width: 100vw;
    background-color: #fff0;
}

.message {
    margin: auto;
    border: 1px solid grey;
    box-shadow: 5px 5px #aaa;
    padding: 2rem;
}

.busy {
    color: #232323;
    font-style: italic;
    cursor: progress;
}

.main {
    margin-bottom: 42px;
}

.footer {
    position: fixed;
    bottom: 0;
    height: 34px;
    font-size: 17px;
    background-color: white;
    border-top: 2px solid #cfcfcf;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media print {
    .App .top {
        display: none;
    }
    .App .print {
        display: block;
    }

    .footer {
        display: none;
    }
}
.error .clear.button {
    font-size: 18px;
    padding: 2px;
    border: none;
    position: relative;
    top: -5px;
    right: -5px;
}

.error {
    position: absolute;
    bottom: 5px; right: 5px;
    background-color: #ffffcf;
}

.error label {
    display: inline-block;
}
.hide {
    display: none;
}
.error .show {
    display: block;
    
}

.userDetails {
    display: flex;
    flex-wrap: wrap;
}

.detailSet {
    margin-left: auto;
    margin-right: auto;
}
.detailSet:first-child {
    margin-left: 0;
}
.detailSet:last-child {
    margin-right: 0;
}
.detailSet .userDetail.timestamp:after {
    content : ' - ';
}
